import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment: ENV = {
    production: false,
    type: 'the-strongest',
    apiUrl: 'https://thestrongest.gateway.test.todotix.com/',
    front_url: 'https://thestrongest.test.front.solunes.com/bypass-login',

    title: 'Club The Strongest',
    subtitle: 'Admin Membresías',
    description: `"Club The Strongest - Admin Membresías" es un sitio en el que puedes administrar el sitio web de Club The Strongest Membresías`,
    keywords: 'Club The Strongest, Admin Membresías',
    yearProyect: '2024',

    imgLogo: '/assets/img/logo.png',
    imgLogoSVG: '/assets/img/logo.svg',
    imgLogoBig: '/assets/img/logo-big.png',

    header: true,
    sidebar: true,
    footer: true,

    authType: 'auth-2',
    authGoogle: false,
    authFacebook: false,
    authApple: false,
    authGithub: false,

    authRedirect: '/',

    //* Firebase
    firebase: {
        "projectId":"clubbolivar-membresias",
        "appId":"1:924925915724:web:9f327aa12495b5d7c9fae9",
        "storageBucket":"clubbolivar-membresias.appspot.com",
        "apiKey":"AIzaSyBn7I3w7JFAJcP-AVJNWhht25jhyGUgN2M",
        "authDomain":"clubbolivar-membresias.firebaseapp.com",
        "messagingSenderId":"924925915724",
        "measurementId":"G-J741BLEVFD",
    }
};
